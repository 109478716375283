<form #prEntryForm="ngForm" (isValid)="updateProductDetails($event)" [formGroup]="productEntryEditForm">
    <h4 mat-dialog-title>Edit</h4>
    <div class="mb-2" mat-dialog-content>
        <div class="row sm-gutters">
            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>{{'common.product' | translate}}</mat-label>
                <input [error]="productId" formControlName="product" autocomplete="new-product" type="text"
                    placeholder="Product" aria-label="product" matInput [matAutocomplete]="product" cdkOverlayOrigin
                    #trigger="cdkOverlayOrigin">
                <mat-autocomplete (optionSelected)="onProductSelected($event)" autoActiveFirstOption
                    #product="matAutocomplete">
                    <mat-option *ngFor="let option of (productOptions | async);" [value]="option.prodName.toUpperCase()"
                        [id]="option.productCd">
                        {{option.prodName.toUpperCase()}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error #productId></mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>{{'common.quantity' | translate}}</mat-label>
                <input autocomplete="off" type="number" [error]="quantity" formControlName="quantity" matInput
                    placeholder="e.g 15">
                <mat-error #quantity></mat-error>
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions class="pull-right">
        <button (click)="onNoClick()" mat-raised-button mat-dialog-close>Close</button>
        <button [disabled]="productEntryEditForm.invalid" type="submit" color="accent" mat-raised-button
            mat-dialog-close>Update</button>
    </div>
</form>