import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductGroupService {

  constructor(
    private http: HttpClient
  ) { }

 
  createProductGroup(data: ProductGroupModel) {

    const url = "/api/ProductGroup/CreateProductGroup";
    return this.http.post<any>(url, data);
  }

  updateProductGroup(data: ProductGroupModel) {

    const url = "/api/ProductGroup/UpdateProductGroup";
    return this.http.put<any>(url, data);
  }

  getProductGroupList() {

    const url = `/api/ProductGroup/GetAll/${10000}/${1}`;
    return this.http.get<Array<ProductGroupModel>>(url);
  }
  
  getProductGroupId(groupid: number) {

    const url = `/api/ProductGroup/GetProductGroupByID/${groupid}`;
    return this.http.get<ProductGroupModel>(url);
  }

  deleteProductGroupByID(productGroupId: number) {
    const url = `/api/ProductGroup/DeleteProductGroup/${productGroupId}`;
    return this.http.delete<any>(url);
  }

  productGroupDropDown(): Observable<Array<ProductGroupDropDown>> {
    const url = "/api/ProductGroup/GetAllForSelection";
    return this.http.get<Array<ProductGroupDropDown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: ProductGroupDropDown = {
            prodgroupid: item.prodgroupid,
            groupdesc: item.groupdesc
          }
          return data;
        })
      })
    );
  }


}

export interface ProductGroupModel
{
    prodgroupid: number,
    groupsdesc: string,
    groupdesc: number,
    active: boolean,
    visible: boolean
}

export interface ProductGroupDropDown{
  prodgroupid:number,
  groupdesc:string
}


