import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReasonCodeService {

  constructor(
    private http: HttpClient
  ) { }

 
  createReasonCode(data: ReasonCodeModel) {

    const url = "/api/ReasonCode/CreateReasonCode";
    return this.http.post<any>(url, data);
  }

  updateReasonCode(data: ReasonCodeModel) {

    const url = "/api/ReasonCode/UpdateReasonCode";
    return this.http.put<any>(url, data);
  }

  getReasonCodeList() {

    const url = `/api/ReasonCode/GetAll/${10000}/${1}`;
    return this.http.get<Array<ReasonCodeModel>>(url);
  }
  
  getReasonCodeById(reasonid: number) {

    const url = `/api/ReasonCode/GetReasonCodeByCode/${reasonid}`;
    return this.http.get<ReasonCodeModel>(url);
  }

  deleteReasonCodeByID(reasonCodeId: number) {
    const url = `/api/ReasonCode/DeleteReasonCode/${reasonCodeId}`;
    return this.http.delete<any>(url);
  }


}

export interface ReasonCodeModel
{
 reasonCodeId: number,
 reasonCode: string,
 transTypeId: number,
 transType: string,
 accTypeId: string,
 active: boolean
}


