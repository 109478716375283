import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '../../../module/material/material.module';
import { DirectiveModule } from '../../../directive/directive.module';
import { PipeModule } from '../../../pipe/pipe.module';
import { SyncfusionGridModule } from '../../../module/grid/syncfusion-grid.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    DirectiveModule,
    PipeModule,
    SyncfusionGridModule
  ]
})
export class ProductInspectionModule { }
