import { Injectable, ApplicationRef } from '@angular/core';
import { FormGroup, AbstractControl, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  private stopValidating: boolean;
  constructor(
    private applicationRef : ApplicationRef
  ) { }


  async isValid(form: FormGroup, element: HTMLElement): Promise<boolean> {
    if (form.pending) {
      if (form.valid) {
        return this.showError(form, element);
      }
      return false;
    }
    return this.showError(form, element);
  }

  private async showError(form: FormGroup, element: HTMLElement): Promise<boolean> {
    if (form.invalid) {

      this.markDirtyAndTouchAllControl(form);

      let fields: Array<string> = [];
      this.stopValidating = false;

      await this.focusOnInvalidControls(form, element, fields);
      
      return form.valid;
    }

    return form.valid;
  }

  private markDirtyAndTouchAllControl(controls: FormGroup | FormControl) {

    if (controls instanceof FormGroup) {

      Object.keys(controls.controls).forEach((key: string) => {
        
        const abstractControl = controls.controls[key];

        if (abstractControl instanceof FormGroup) {

          this.markDirtyAndTouchAllControl(abstractControl);

        } else if (abstractControl instanceof FormControl) {

          abstractControl.markAsDirty();
          abstractControl.markAsTouched();
          abstractControl.updateValueAndValidity();

        } else {
          console.warn("conditions not matched");
        }

      });

    } else if (controls instanceof FormControl) {
      controls.markAsDirty();
      controls.markAsTouched();
      controls.updateValueAndValidity();
    }
  }
  
  
  private focusOnInvalidControls(controls: FormGroup | FormControl, element: HTMLElement, selectorList: Array<string>, parentSelector: string = null): Promise<void> {
    return new Promise(async (resolve, rejects) => {

      if (controls instanceof FormGroup) {

        Object.keys(controls.controls).every(async (key: string) => {
          
          const abstractControl = controls.controls[key];

          if (abstractControl instanceof FormGroup) {

            if (parentSelector === null) {

              parentSelector = `${key}.`;

            } else {
              parentSelector += `${key}.`;
            }
            this.focusOnInvalidControls(abstractControl, element, selectorList, parentSelector);
            parentSelector = null;
          } else if (abstractControl instanceof FormControl) {

            if (this.stopValidating) {
              resolve();
              return true;
            }

            if (parentSelector === null) {
              selectorList.push(`[formcontrolname='${key}']`);
              const isValid = await this.validateControls(abstractControl, element, `[formcontrolname='${key}']`);
              
              if (!isValid) {
                this.stopValidating = true;
                resolve();
                return true;
              }
            } else {
              let selector = "";
              const depth = parentSelector.split(".");
              for (let i = 0; i < depth.length - 1; i++) {
                selector += ` [formgroupname='${depth[i]}']`;
              }
              selector += ` [formcontrolname='${key}']`;
              selectorList.push(selector);
              const isValid = await this.validateControls(abstractControl, element, selector);
              
              if (!isValid) {
                this.stopValidating = true;
                resolve();
                return true;
              }
            }

          }
        });

      } else if (controls instanceof FormControl) {
        
        Object.keys(controls).every(async (key: string) => {
          const abstractControl = controls[key];
          if (parentSelector == null) {
            selectorList.push(`[formcontrolname='${key}']`);
          } else {
            let selector = "";
            const depth = parentSelector.split(".");

            for (let i = 0; i < depth.length - 1; i++) {
              selector += ` [formgroupname='${depth[i]}']`;
            }

            selector += ` [formcontrolname='${key}']`;
            selectorList.push(selector);
            const isValid = await this.validateControls(abstractControl, element, selector);

            if (!isValid) {
              this.stopValidating = true;
              resolve();
              return true;
            }
          }
        })

      }

    });

  }

  private async isAsyncValid(controls: AbstractControl): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let wait = setInterval(() => {
        if (controls.pending == false) {
          clearInterval(wait);
          resolve(true)
        }
      }, 100)
    })
  }

  private validateControls(property: AbstractControl, element: HTMLElement, selector: string): Promise<boolean> {
    return new Promise(async (resolve, rejects) => {
      if ((property.invalid || !property.valid) && !property.pending) {
        const input = element.querySelector(selector) as HTMLInputElement;
        if (input) {
          if (input.focus instanceof Function) {
            if (this.stopValidating) {
              resolve(false);
            } else {
              this.stopValidating = true;
              input.focus();
              this.applicationRef.tick();
              resolve(false);
            }
            
          }
        }
      } else {
        if (property.pending) {
          await this.isAsyncValid(property);
          if ((property.invalid || !property.valid) && !property.pending) {
            const input = element.querySelector(selector) as HTMLInputElement;
            if (input) {
              if (input.focus instanceof Function) {
                if (this.stopValidating) {
                  resolve(false);
                } else {
                  this.stopValidating = true;
                  input.focus();
                  this.applicationRef.tick();
                  resolve(false);
                }
              }
            }
          }
        }
      }
      resolve(true);
    });

  }
}
