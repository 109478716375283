import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DepartmentService {

    constructor(
        private http: HttpClient
    ) { }


    createDepartment(data: DepartmentModel) {

        const url = "/api/Department/CreateDepartment";
        return this.http.post<any>(url, data);
    }

    updateDepartment(data: DepartmentModel) {

        const url = "/api/Department/UpdateDepartment";
        return this.http.put<any>(url, data);
    }

    getDepartmentList() {

        const url = `/api/Department/GetAll/${10000}/${1}`;
        return this.http.get<Array<DepartmentModel>>(url);
    }

    getDepartmentById(DepartmentId: number) {

        const url = `/api/Department/GetDepartmentById/${DepartmentId}`;
        return this.http.get<DepartmentModel>(url);
    }

    deleteDepartmentByID(DepartmentId: number) {
        const url = `/api/Department/DeleteDepartment/${DepartmentId}`;
        return this.http.delete<any>(url);
    }

    departmentDropdown(): Observable<Array<DepartmentDropDownModel>> {
        const url = "/api/Department/GetAllSelection";
        return this.http.get<Array<DepartmentDropDownModel>>(url).pipe(
            map(data => {
                return data.map(item => {
                    const data: DepartmentDropDownModel = {
                        departmentId: item.departmentId,
                        departmentD: item.departmentD
                    }
                    return data;
                })
            })
        );
    }


}

export interface DepartmentModel {
    departmentId: number,
    departmentSd: string,
    departmentD: string,
}

export interface DepartmentDropDownModel {
    departmentId: number,
    departmentD: string
}




