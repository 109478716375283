import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { LanguageService } from '../../../services/language/language.service';

@Injectable()
export class ProductionInspectionLanguageResolver implements Resolve<number> {

    constructor(
        private languageService: LanguageService,
        private translateService: TranslateService,
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<number> {
        
        this.translateService.set('prdi.receive-no', `Receive No.`, 'en');
       
        
       
        return this.languageService.loadLocalResource('grc');
    }
}