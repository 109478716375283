import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ProductDropdown, ProductService } from 'src/app/services/product/product.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';


@Component({
  selector: 'pp-product-line-edit',
  templateUrl: './product-line-edit.component.html',
  styleUrls: ['./product-line-edit.component.scss']
})
export class ProductLineEditComponent implements OnInit {
  productEntryEditForm: FormGroup;

  products: Array<ProductDropdown> = [];
  productOptions: Observable<Array<ProductDropdown>>;
  productCodeSelected: string;

  constructor(
    public dialogRef: MatDialogRef<ProductLineEditComponent>,
    private fb: FormBuilder,
    private productService: ProductService,
    @Inject(MAT_DIALOG_DATA) public data: ProdcutEdit
  ) {

  }

  private _productFilter(value: string): Array<ProductDropdown> {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.products.filter(option => option.prodName.toLowerCase().includes(filterValue));
    } else {
      return this.products;
    }
  }

  onProductSelected(event: MatAutocompleteSelectedEvent) {
    if (event.option.id) {
      this.productCodeSelected = event.option.id;
    }
  }

  ngOnInit() {
    this.productEntryEditForm = this.fb.group({
      product: [this.data.product, Validators.required],
      quantity: [this.data.quantity, Validators.required],
    });

    this.productCodeSelected = this.data.productId;

    this.productOptions = this.productEntryEditForm.get('product').valueChanges
      .pipe(
        startWith(''),
        map(value => this._productFilter(value))
      );

    this.productService.productDropdown().subscribe(data => {
      this.products = data;
    })
  }
  onNoClick(): void {
    this.dialogRef.close(null);
  }

  updateProductDetails(isValid: boolean) {
    if (isValid) {
      const result: ProdcutEdit = {
        product: this.productEntryEditForm.get('product').value,
        productId: this.productCodeSelected,
        quantity: this.productEntryEditForm.get('quantity').value
      };
      this.dialogRef.close(result);
    } else {
      console.log("invalid");
    }
  }
}

export interface ProdcutEdit {
  product: string;
  productId: string;
  quantity: number;
}