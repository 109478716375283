import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StateService {

    constructor(
        private http: HttpClient
    ) { }


    createState(data: StateModel) {

        const url = "/api/State/CreateState";
        return this.http.post<any>(url, data);
    }

    updateState(data: StateModel) {

        const url = "/api/State/UpdateState";
        return this.http.put<any>(url, data);
    }

    getStateList() {

        const url = `/api/State/GetAll/${10000}/${1}`;
        return this.http.get<Array<StateModel>>(url);
    }

    getStateById(statecd: number) {

        const url = `/api/State/GetStateByCode/${statecd}`;
        return this.http.get<StateModel>(url);
    }

    deleteStateByID(stateCd: number) {
        const url = `/api/State/DeleteState/${stateCd}`;
        return this.http.delete<any>(url);
    }


}

export interface StateModel {
    stateCd: string,
    stateD: number,
    countryCd: string,
    countryName: string,
    active: boolean,
    visible: boolean
}


