import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { LanguageService } from './../../../services/language/language.service';

@Injectable()
export class DispatchPlanLanguageResolver implements Resolve<number> {

    constructor(
        private languageService: LanguageService,
        private translateService: TranslateService,
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<number> {

        this.translateService.set('dsp.caption', `Dispatch Plan`, 'en');
        this.translateService.set('dsp.vehicle', `Vehicle`, 'en');
        this.translateService.set('dsp.despatch-order-date', `Despatch Order Date`, 'en');
        this.translateService.set('dsp.sales-order-no', `Sales Order No`, 'en');
        this.translateService.set('dsp.order-date', `Order Date`, 'en');

        return this.languageService.loadLocalResource('dsp');
    }
}