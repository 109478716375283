import { Injectable } from '@angular/core'
import { Resolve, RouterStateSnapshot } from '@angular/router'
import { ActivatedRouteSnapshot } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'

import { LanguageService } from './../../../services/language/language.service'

@Injectable()
export class ShipmentOrderLanguageResolver implements Resolve<number> {
  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<number> {
    this.translateService.set('shp.shipment-date', `Shipment Date`, 'en')
    this.translateService.set(
      'shp.despatch-order-date',
      `Despatch Order Date`,
      'en',
    )
    this.translateService.set('shp.caption', `Shipment Order`, 'en')
    this.translateService.set('shp.to-country', `To Country`, 'en')
    this.translateService.set('shp.from-country', `From Country`, 'en')
    this.translateService.set('shp.dispatch-order', `Dispatch Order`, 'en')
    this.translateService.set(
      'shp.dispatch-order-no',
      `Despatch Order No`,
      'en',
    )
    this.translateService.set('shp.route-name', `Route Name`, 'en')
    this.translateService.set('shp.vehicle', `Vehicle`, 'en')
    this.translateService.set('shp.start-post-code', `Start Post Code`, 'en')
    this.translateService.set('shp.end-post-code', `End Post Code`, 'en')
    this.translateService.set('shp.emission-code', `Emission Code`, 'en')
    this.translateService.set('shp.capacity', `Capacity`, 'en')
    this.translateService.set('shp.toll', `Toll`, 'en')
    this.translateService.set('shp.distance', `Distance`, 'en')
    this.translateService.set('shp.from-location', `From Location`, 'en')
    this.translateService.set('shp.to-location', `To Location`, 'en')
    this.translateService.set('shp.transport-cost', `Transport Cost`, 'en')
    this.translateService.set('shp.total-cost', `Total Cost`, 'en')
    this.translateService.set('shp.route-details', `Route Details`, 'en')

    return this.languageService.loadLocalResource('shp')
  }
}
