import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { LanguageService } from './../../../services/language/language.service';

@Injectable()
export class BusinessPartnerLanguageResolver implements Resolve<number> {

    constructor(
        private languageService: LanguageService,
        private translateService: TranslateService,
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<number> {
        this.translateService.get('bp.master.caption.insert').subscribe(data => {

            if (data == 'bp.master.caption.insert') {
                this.translateService.set('bp.master.caption.insert', `Business Partner Insert`, 'en');
                this.translateService.set('bp.master.bp-code', `BP Code`, 'en');
                this.translateService.set('bp.master.bp-ext-Custno', `Ext Cust No`, 'en');
                this.translateService.set('bp.master.bp-Name', `NAME`, 'en');
                this.translateService.set('bp.master.bp-Country', `Country`, 'en');
                this.translateService.set('bp.master.bp-City', `City`, 'en');
                this.translateService.set('bp.master.bp-PostCode', `Post Code`, 'en');
                this.translateService.set('bp.master.bp-Address', `Address`, 'en');
                this.translateService.set('bp.master.bp-Phone1', `Phone1`, 'en');
                this.translateService.set('bp.master.bp-Email', `Email`, 'en');
                this.translateService.set('bp.master.bp-ContactName', `Contact Name`, 'en');
                this.translateService.set('bp.master.bp-GroupName', `GroupName`, 'en');
                this.translateService.set('bp.master.bp-Type', `Type`, 'en');

                this.translateService.set('bp.master.caption.edit', `Edit`, 'en');
                this.translateService.set('bp.master.bp', `Bp`, 'en');
                this.translateService.set('bp.master.bp-Address', `Address`, 'en');
                this.translateService.set('bp.master.bp-product', `Product`, 'en');
                this.translateService.set('bp.master.bp-contact', `Contact`, 'en');
                this.translateService.set('bp.master.bp-vehicle', `Vehicle`, 'en');
                this.translateService.set('bp.master.bp-relation', `Relation`, 'en');
                this.translateService.set('bp.master.bp-depot', `Depot`, 'en');
                this.translateService.set('bp.master.bp-user', `User`, 'en');
                
                this.translateService.set('bp.master.bp-Group', `Group`, 'en');
                this.translateService.set('bp.master.bp-CustNo', `Cust No`, 'en');
                this.translateService.set('bp.master.bp-Name', `Name`, 'en');
                this.translateService.set('bp.master.bp-creditDays', `CreditDays`, 'en');
                this.translateService.set('bp.master.bp-Phone1', `Phone1`, 'en');
                this.translateService.set('bp.master.bp-Phone2', `Phone2`, 'en');
                this.translateService.set('bp.master.bp-Fax', `Fax`, 'en');
                this.translateService.set('bp.master.bp-invoice-email', `Invoice Email`, 'en');
                this.translateService.set('bp.master.bp-Website', `Website`, 'en');
                this.translateService.set('bp.master.bp-Currency', `Currency`, 'en');
                this.translateService.set('bp.master.bp-VATNo', `VAT No`, 'en');
                this.translateService.set('bp.master.bp-VATRate', `VAT Rate %`, 'en');
                this.translateService.set('bp.master.bp-Active', `Active`, 'en');

                this.translateService.set('bp.address-AddressName', `Address Name`, 'en');
                this.translateService.set('bp.address-AddressDesc', `Address Desc`, 'en');
                this.translateService.set('bp.address-PinCode', `Pin Code`, 'en');
                this.translateService.set('bp.address-AddressType', `AddressType`, 'en');
                this.translateService.set('bp.address-Country', `Country`, 'en');
                this.translateService.set('bp.address-states', `States`, 'en');
                this.translateService.set('bp.address-city', `City`, 'en');
                this.translateService.set('bp.address-Pincode', `PinCode`, 'en');
                this.translateService.set('bp.address-StreetName', `StreetName`, 'en');
                this.translateService.set('bp.address-PhoneOne', `Phone 1`, 'en');
                this.translateService.set('bp.address.caption.insert', `Business Partner Address Insert`, 'en');


                this.translateService.set('bp.product-productCd', `ProductCd`, 'en');
                this.translateService.set('bp.product-capping', `Capping`, 'en');
                this.translateService.set('bp.product-cappingMinQty', `CappingMinQty`, 'en');
                this.translateService.set('bp.product-cappingMaxQty', `CappingMaxQty`, 'en');
                this.translateService.set('bp.product-minQty', `MinQty`, 'en');
                this.translateService.set('bp.product-maxQty', `MaxQty`, 'en');

                this.translateService.set('bp.product.caption.insert', `Business Partner Product Insert`, 'en');
                this.translateService.set('bp.product-Product', `Product`, 'en');
                this.translateService.set('bp.product-minStockQty', `minStockQty`, 'en');
                this.translateService.set('bp.product-maxStockQty', `maxStockQty`, 'en');
                this.translateService.set('bp.product.capping', `Capping`, 'en');
                this.translateService.set('bp.product.priority', `Priority`, 'en');

                //Contact Info

                this.translateService.set('bp.contact.caption.insert', `Business Partner Contact Insert`, 'en');
                this.translateService.set('bp.contact-contactName', `Contact Name`, 'en');
                this.translateService.set('bp.contact-departmentname', `Department`, 'en');
                this.translateService.set('bp.contact-phone', `Telephone`, 'en');
                this.translateService.set('bp.contact-mobile', `Mobile`, 'en');
                this.translateService.set('bp.contact-email', `Email`, 'en');
                this.translateService.set('bp.contact-fax', `Fax`, 'en');

                //Vehicle Info

                this.translateService.set('bp.vehicle.caption.insert', `Business Partner Vehicle Insert`, 'en');
                this.translateService.set('bp.vehicle-pref', `Preference`, 'en');
                this.translateService.set('bp.vehicle-vehiclename', `Vehicle`, 'en');
                this.translateService.set('vehicle-vehiclename', ` Name`, 'en');
                this.translateService.set('vehicle-vehicletype', ` Type`, 'en');
                this.translateService.set('vehicle-ownership', `  Owner`, 'en');
                this.translateService.set('vehicle-vehicleregno', ` Registation No`, 'en');
                this.translateService.set('vehicle-vehicledesc', ` Description`, 'en');
                this.translateService.set('vehicle-vlength', ` Length`, 'en');
                this.translateService.set('vehicle-vwidth', ` Width`, 'en');
                this.translateService.set('vehicle-vcapacity', `Capacity`, 'en');
                this.translateService.set('vehicle-visible', ` Visiable`, 'en');


                //Relation Info

                this.translateService.set('bp.relation.caption.insert', `Business Partner Relation Insert`, 'en');
                this.translateService.set('bp.relation-relationName', `Relation Name`, 'en');
                this.translateService.set('bp.relation-customerno', `Customer No`, 'en');
                this.translateService.set('bp.relation-autoinbound', `Auto Inbound`, 'en');
                this.translateService.set('bp.relation-manuallyinbound', `Manually Inbound`, 'en');
                this.translateService.set('bp.relation-viainterface', `Via Interface`, 'en');
                this.translateService.set('bp.relation-inboundtransittime', `Inbound Transit Time`, 'en');
                this.translateService.set('bp.relation-relationtype', `Relation Type`, 'en');
                this.translateService.set('bp.relation-relationBpName', `BP Name`, 'en');
                this.translateService.set('bp.relation-relationTypeDesc', `BP Type`, 'en');
                this.translateService.set('bp.relation-bpaliasName', `EXT Customer No`, 'en');

                //Depot Info
                this.translateService.set('bp.depot.caption.insert', `Business Partner Warehouse Insert`, 'en');
                this.translateService.set('bp.depot-preference', `Preference`, 'en');
                this.translateService.set('bp.depot-warehouseName', `Warehouse`, 'en');
                this.translateService.set('bp.depot-haulierName', `Haulier`, 'en');
                this.translateService.set('bp.depot-transitTime', `Transit Time`, 'en');

                // User Info
                //set global language service

            }
        });
        return this.languageService.loadLocalResource('bp');
    }
}