import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ProductStatusService {

    constructor(
        private http: HttpClient
    ) { }


    createProductStatus(data: ProductStatusModel) {

        const url = "/api/ProductStatus/CreateProductStatus";
        return this.http.post<any>(url, data);
    }

    updateProductStatus(data: ProductStatusModel) {

        const url = "/api/ProductStatus/UpdateProductStatus";
        return this.http.put<any>(url, data);
    }

    getProductStatusList() {

        const url = `/api/ProductStatus/GetAll/${10000}/${1}`;
        return this.http.get<Array<ProductStatusModel>>(url);
    }

    getProductStatusById(statuscd: number) {

        const url = `/api/ProductStatus/GetProductStatusByCode/${statuscd}`;
        return this.http.get<ProductStatusModel>(url);
    }

    deleteProductStatusByID(statusCd: number) {
        const url = `/api/ProductStatus/DeleteProductStatus/${statusCd}`;
        return this.http.delete<any>(url);
    }


}

export interface ProductStatusModel {
    statusCd: number,
    statusSd: string,
    statusD: string,
    allowOrder: boolean,
    ordDescr: string,
    active: boolean,
    stdstatus: string,
    transactionTypeDetail: Array<TransactionTypeDetailModel>
    
}

export interface TransactionTypeDetailModel {

    transTypeId: number,
    transType: string,
    statusCd: number
}


